import React from 'react'
import { BanknotesIcon, CircleStackIcon, BriefcaseIcon, LifebuoyIcon, HeartIcon, HomeIcon, TrophyIcon, ShareIcon, ForwardIcon } from '@heroicons/react/24/outline'
import { icon } from '@fortawesome/fontawesome-svg-core'

const PlanningSection = () => {

    const steps = [
        {
            title: 'Goals & Objectives',
            icon: TrophyIcon,
            description: 'Reviewing and prioritizing your goals and objectives.',
            type: 'primary',
        },
        {
            title: 'Cash Flow',
            icon: BanknotesIcon,
            description: 'Developing a set of action steps to work towards your goals, and a plan for following through with them.',
            type: 'secondary',
        },
        {
            title: 'Investments',
            icon: CircleStackIcon,
            description: 'Reviewing your current investments and recommending a strategy that is consistent with your goals and objectives.',
            type: 'secondary',
        },
        {
            title: 'Tax Planning',
            icon: BriefcaseIcon,
            description: 'Identifying strategies to minimize taxes over your lifetime and for your heirs.',
            type: 'secondary',
        },
        {
            title: 'Risk Management & Insurance',
            icon: LifebuoyIcon,
            description: 'Identifying risks to your financial life and recommending options to minimize or mitigate those risks.',
            type: 'secondary',
        },
        {
            title: 'Giving Strategy',
            icon: HeartIcon,
            description: 'Guidance on making your giving more efficient and impactful to the individuals or organizations that you are passionate about.',
            type: 'secondary',
        },
        {
            title: 'Estate Planning',
            icon: HomeIcon,
            description: 'Developing a plan to provide for your loved ones, carry out your wishes, and minimize taxes in the event of your death.',
            type: 'secondary',
        },
        {
            title: 'Referral',
            icon: ShareIcon,
            description: 'Referral to other professionals, as required, to assist with implementation of the action plan.',
            type: 'secondary',
        },
        {
            title: 'Ongoing Monitoring',
            icon: ForwardIcon,
            description: 'Ongoing accountability and progress monitoring so that you know where you stand.',
            type: 'secondary',
        },
    ]

    const items = [
        {
            title: "Cash Flow",
            icon: BanknotesIcon,
        },
        {
            title: "Investments",
            icon: CircleStackIcon,
        },
        {
            title: "Tax Planning",
            icon: BriefcaseIcon,
        },
        {
            title: "Insurance",
            icon: LifebuoyIcon,
        },
        {
            title: "Giving Strategy",
            icon: HeartIcon,
        },
        {
            title: "Estate Planning",
            icon: HomeIcon,
        },
    ]

    return (
        <section className='border-t'>
            <div className="mx-auto container my-12 flex flex-col xl:flex-row xl:space-x-6">


                <div className='basis-1/2'>
                    <h3 className='mb-2'>Financial Planning</h3>
                    <p class='text-left leading-relaxed text-gray-500 mb-8'>
                        Do I have enough for retirement? How do I pay less in taxes? How do I make sure my family is taken care of after I'm gone? Financial planning can help answer these questions and many more. As your financial planner, we help provide clarity and direction when it comes to your financial future. We help you set goals, create a place to reach those goals, and work to optimize your investments, minimize your taxes, and mitigate any risks that could derail your progress. Whether you are overwhelmed by money decisions and need education and guidance, you have a handle on your finances and just need a second opinion or help optimizing, or you don't have the time or interest to focus on your finances and want to outsource and automate most of the work, our financial planning services are for you.
                    </p>
                    {/* <div className="grid gap-4 grid-cols-3 mb-16">
                        {items.map((item) => (
                            <div className="flex items-center mb-4">
                                <item.icon className="h-6 w-6 text-blue-500 mr-2" />
                                <p className='text-gray-500'>{item.title}</p>
                            </div>
                        ))}
                    </div> */}
                    <small className="flex-none">Cost</small>
                    <p className="mt-4">
                        Typical planning fee is $3,600 per year. Fee may be more or less based on complexity and will be quoted before we begin the engagement. Planning fee may
                        be waived if you engage us to manage $2M of assets or more.
                    </p>
                </div>

                {/* <div className="basis-1/2 mt-8 lg:mt-0 rounded-2xl border flex flex-col divide-y bg-gray-100"> */}
                <div className="basis-1/2 mt-8 xl:mt-0 rounded-2xl grid grid-cols-2 bg-gray-100 border border-gray-200">
                    {/* {steps.filter(step => step.type === 'primary').map((step) => (
                        <div className='p-6'>
                            <div className="flex space-x-2 items-center">
                                <step.icon className='h-8 w-8 text-blue-500' />
                                <h4 className='text-base font-medium'>{step.title}</h4>
                            </div>
                            <p className='text-sm ml-10'>{step.description}</p>
                        </div>
                    ))}

                    <div className='p-6 grid grid-cols-2 gap-4'>
                        {steps.filter(step => step.type === 'secondary').map((step) => (
                            <div className="flex space-x-2 items-center">
                                <step.icon className='h-8 w-8 text-blue-500' />
                                <h4 className='text-base font-medium'>{step.title}</h4>
                            </div>
                        ))}
                    </div> */}

                    {steps.map((step) => (
                        <div className={`px-6 py-6 ${step.type === 'primary' ? 'col-span-2 ' : ''}`}>
                            <div className='flex space-x-2 items-center'>
                                <step.icon className='h-6 w-6 text-blue-500' />
                                <h4 className='text-base font-medium'>{step.title}</h4>
                            </div>
                            <p className='text-sm ml-10'>{step.description}</p>
                        </div>
                    ))}
                </div>

            </div>
        </section>
    )
}

export default PlanningSection