import { useState } from 'react'
import React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import Cta from '../../components/sections/Cta'


import SplitImage2 from '../../components/sections/SplitImage2'
import InvestmentsSection from '../../components/sections/InvestmentsSection'
import BudgetSection from '../../components/sections/BudgetSection'
import { ChartPieIcon, CircleStackIcon, CalculatorIcon } from '@heroicons/react/24/outline'

import { Tab, Dialog, DialogPanel } from '@headlessui/react'
import PlanningSection from '../../components/sections/PlanningSection'


const Individual = () => {
    let [isOpen, setIsOpen] = useState(false)

    const services = [
        {
            title: "Financial Planning",
            subtitle: "Goal based planning",
            description: "We design all components of your financial plan to meet your financial and life goals. We test and monitor your plan, and adjust along the way, so you can have a level of predictability when it comes to your financial future.",
            icon: ChartPieIcon,
        },
        {
            title: "Investment Management",
            subtitle: "Optimize your investment strategy",
            description: "Whether you have many years ahead and are focused on growing your assets, or you are looking for predictable income from your portfolio, our focus is on positioning your assets in a way that helps support your overall financial health, which helps you fulfill your unique purpose in life.",
            icon: CircleStackIcon,
        },
        {
            title: "Budget Coaching",
            subtitle: "Take control of your money",
            description: "Our holistic budget coaching and consulting service combine powerful budgeting software with a simple methodology to help you take control of your money and achieve true financial wellness.",
            icon: CalculatorIcon,
        },
    ]

    return (
        <Layout>
            <Seo title="Individual Wealth Management" />

            <SplitImage2
                imageleft="false"
                heading="Here to serve you and your family"
                subheading="Individual Services"
                content="We provide Financial Planning, Investment Management, and Budget Coaching services for our clients. These services can be utilized individually or all together."
                pic="129"
            />


            <section className="container mx-auto px-5 mt-8 md:mt-12 mb-12 md:mb-24">

                <Tab.Group>

                    <Tab.List className="grid lg:grid-cols-3 lg:divide-x items-start">
                        {services.map((service) => (
                            <Tab className="group active:outline-none active:ring-0">
                                {({ selected }) => (
                                    <div className="flex flex-col px-2 md:px-12 py-8 md:py-12 lg:h-[532px] xl:h-[460px] 2xl:h-[400px]">
                                        <div className="flex flex-col">
                                            <div class={`grow-0 w-16 h-16 inline-flex sm:mr-4 sm:mb-0 mb-2 md:mb-4 self-center items-center justify-center rounded-full flex-shrink-0 ${selected ? 'bg-blue-500' : 'bg-gray-100 group-hover:bg-gray-200 group-hover:scale-110'}`}>
                                                <service.icon className={`h-8 w-8 ${selected ? 'text-blue-100' : 'text-gray-500 group-hover:text-gray-500'}`} />
                                            </div>
                                            <small className={`mt-6 ${selected ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500'}`}>{service.title}</small>
                                            <h3 className={`mb-4 ${selected ? '' : 'text-gray-600 group-hover:text-gray-900'}`}>{service.subtitle}</h3>
                                        </div>
                                        <p class={`text-left leading-relaxed text-sm ${selected ? '' : 'text-gray-400 group-hover:text-gray-500'}`}>{service.description}</p>
                                        {/* <button
                                            onClick={() => setIsOpen(true)}
                                            className="lg:hidden mt-2 mx-auto px-6 py-3 text-lg rounded hover:scale-105 active:scale-100 text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-400 drop-shadow hover:text-white"
                                        >
                                            Learn More
                                        </button> */}
                                    </div>
                                )}
                            </Tab>
                        ))}
                    </Tab.List>
                    <Tab.Panels className="col-span-2 hidden lg:flex">
                        <Tab.Panel>
                            <PlanningSection />
                        </Tab.Panel>
                        <Tab.Panel>
                            <InvestmentsSection />
                        </Tab.Panel>
                        <Tab.Panel>
                            <BudgetSection />
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </section>

            {/* dialog  */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
                <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                    <DialogPanel className="max-w-lg space-y-4 border bg-white p-12">
                        <p>Are you sure you want to deactivate your account? All of your data will be permanently removed.</p>
                        <div className="flex gap-4">
                            <button onClick={() => setIsOpen(false)}>Close</button>
                        </div>
                    </DialogPanel>
                </div>
            </Dialog>

            <Cta />
        </Layout >
    )
}

export default Individual
