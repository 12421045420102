import React, { useState } from 'react';
import Button from '../elements/Button'
import { Disclosure } from '@headlessui/react'
import { BanknotesIcon, LifebuoyIcon, UserIcon, TrophyIcon, AdjustmentsHorizontalIcon, WrenchScrewdriverIcon, WalletIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid'

const BudgetSection = () => {

    const steps = [
        {
            title: 'Personalized Guidance',
            icon: UserIcon,
            description: 'Whether you\'re a budgeting beginner or looking to refine your skills, our service is designed to accommodate any level of experience.',
            type: 'primary',
        },
        {
            title: 'Expert Coaching',
            icon: TrophyIcon,
            description: 'Chandler Batts, our principal financial wellness consultant, is a YNAB-Certified Budget Coach. With Chandler\'s expertise, you will receive tailored advice and strategies that work for your unique financial situation.',
            type: 'secondary',
        },
        {
            title: 'Flexible Options',
            icon: AdjustmentsHorizontalIcon,
            description: 'We offer sessions both virtually and in person in Tyler, Texas, making it easy to get the support you need wherever you are.',
            type: 'secondary',
        },
        {
            title: 'Proven Tools',
            icon: WrenchScrewdriverIcon,
            description: 'The YNAB software simplifies budgeting with intuitive tools that make managing your money straightforward and stress-free.',
            type: 'secondary',
        },
        {
            title: 'Reduce Financial Anxiety',
            icon: LifebuoyIcon,
            description: 'Gain peace of mind knowing exactly where your money is going.',
            type: 'secondary',
        },
        {
            title: 'Increase Savings',
            icon: WalletIcon,
            description: 'Identify areas where you can save more and achieve your financial goals faster.',
            type: 'secondary',
        },
        {
            title: 'Control Your Spending',
            icon: BanknotesIcon,
            description: 'Make informed decisions about your finances and align your spending with your actual desires and priorities.',
            type: 'secondary',
        },
    ]


    const pricing = [
        {
            title: "One-Hour Session",
            price: "$100",
            quantity: "session",
            description: "Perfect for those who need targeted advice or a quick budget check-up. Each one-hour session is tailored to address your specific questions and challenges. Our YNAB-Certified Budget Coach, Chandler Batts, will provide personalized guidance to help you optimize your budget and stay on track.",
            idealFor: "For those seeking flexible, on-demand support.",
            features: ["YNAB-Certified Coaching", "One-hour session", "Personalized guidance", "Targeted advice"]
        },
        {
            title: "Turn-Key Budget Build-Out",
            price: "$350",
            quantity: "budget",
            description: "This comprehensive package is designed to take you from zero to a fully functioning budget. Chandler will work with you to create a detailed budget tailored to your financial goals using the YNAB software. We’ll cover everything from income and expenses to savings strategies, ensuring you have a clear and actionable plan.",
            idealFor: "For those new to budgeting or looking to overhaul their financial planning.",
            features: ["YNAB-Certified Coaching", "Zero to fully functioning budget", "Detailed budget implementation", "Income, expenses, savings, and more"]
        },
        {
            title: "Retained Budget Coaching",
            price: "$1,000",
            quantity: "year",
            description: "Our most immersive and supportive package, this one-year coaching service provides continuous guidance and accountability. You’ll have monthly check-ins with Chandler to review your budget, make adjustments, and stay on track towards your financial goals. This package ensures you have a trusted advisor by your side throughout the year.",
            idealFor: "For those seeking ongoing support and long-term personal financial coaching.",
            features: ["YNAB-Certified Coaching", "Our most immersive and supportive package", "Monthly check-ins", "Continuous guidance", "Ongoing support"]
        }
    ]




    return (
        <>
            <section className='border-t'>
                <div className="mx-auto container my-12 flex flex-col xl:flex-row xl:space-x-6">


                    <div className='basis-1/2'>
                        <h3 className='mb-2'>Budget Coaching</h3>
                        <p class='text-left leading-relaxed text-gray-500 mb-8'>
                            With PLG Budget Coaching, you can go from never having budgeted before to managing a fully functioning budget that actually works in just a few meetings! These tools along with guidance from our certified budget coach can take the hassle and confusion out of budgeting and help you see meaningful change in your financial life. We offer a range of packages to fit your specific needs and goals. Whether you need a single session to fine-tune your budget or a comprehensive plan to transform your financial life, we have the perfect option for you.
                        </p>
                        <small className="flex-none">Cost</small>
                        <div className="mt-4 flex space-x-2">
                            {pricing.map((price) => (
                                <div className="w-1/3 p-4 rounded-lg bg-blue-50 border border-blue-100">
                                    <h4 className='text-lg font-medium text-center xl:h-14'>{price.title}</h4>
                                    <p className='text-sm pt-2 xl:h-20'>{price.idealFor}</p>
                                    <div className="flex justify-center items-end my-4">
                                        <p className='text-3xl font-semibold text-black'>{price.price}</p>
                                        <p className='text-sm ml-1 pb-1 font-medium text-black'>/{price.quantity}</p>
                                    </div>
                                    {/* <p className='text-sm'>{price.description}</p> */}
                                    <div className="pt-4">
                                        {price.features.map((feature) => (
                                            <div className="flex items-top mb-4">
                                                <CheckCircleIcon className="h-4 w-4 text-blue-500 mr-2 flex-shrink-0" />
                                                <p className='text-gray-500 text-sm'>{feature}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="basis-1/2 mt-8 xl:mt-0 rounded-2xl border flex flex-col bg-gray-100">
                        {steps.map((step) => (
                            <div className={`px-6 py-6 ${step.type === 'primary' ? 'col-span-2 ' : ''}`}>
                                <div className='flex space-x-2 items-center'>
                                    <step.icon className='h-6 w-6 text-blue-500' />
                                    <h4 className='text-base font-medium'>{step.title}</h4>
                                </div>
                                <p className='text-sm ml-10'>{step.description}</p>
                            </div>
                        ))}
                    </div>

                </div>
            </section>

        </>
    )
}

export default BudgetSection;