import React, { useState } from 'react';
import Button from '../elements/Button'
import { Disclosure } from '@headlessui/react'
import { TrophyIcon, CircleStackIcon, BriefcaseIcon, LifebuoyIcon, HeartIcon, HomeIcon } from '@heroicons/react/24/outline';

const InvestmentsSection = () => {


    const steps = [
        {
            title: 'Comfort',
            icon: TrophyIcon,
            description: 'If you have ever invested on your own, you have probably had hesitations about whether you are making the right choice. We have the expertise and experience to ease those fears.',
            type: 'secondary',
        },
        {
            title: 'Performance',
            icon: CircleStackIcon,
            description: 'Most individual investors significantly underperform the market because they let their emotions lead them to buy and sell at the absolute worst time, they hold under diversified portfolios, they follow the advice of media and clueless friends and family members, they trade too often, and often times they are either too aggressive or too conservative for their risk tolerance and goals.',
            type: 'secondary',
        },
        {
            title: 'Proper Allocation',
            icon: BriefcaseIcon,
            description: 'We can help translate you own risk tolerance, goals, time horizon, and tax status into the percentage of stocks, bonds, real estate, currencies, etc. that fits your situation, and what type of accounts you should hold them in.',
            type: 'secondary',
        },
        {
            title: 'Save Time',
            icon: LifebuoyIcon,
            description: 'You can probably manage your own investments. But how much time are you willing to spend learning what you need to know and doing what you need to do to be confident that you are making the right choices?',
            type: 'secondary',
        },
        {
            title: 'Organization',
            icon: HeartIcon,
            description: 'Do you know how much you have and where it is all at? Just as important, do your spouse and heirs know? We can help simplify and organize your investments so that your financial life is easier to manage.',
            type: 'secondary',
        },
        {
            title: 'Asset Location',
            icon: HomeIcon,
            description: 'Taxes can erode otherwise good investment returns. We can help minimizing future taxes by placing the right assets in the right accounts, contributing to the right type of account, and taking distributions from the right account at the right time.',
            type: 'secondary',
        },
    ]

    return (
        <>
            <section className='border-t'>
                <div className="mx-auto container my-12 flex flex-col xl:flex-row xl:space-x-6">


                    <div className='xl:basis-1/2'>
                        <h3 className='mb-2'>Investment Management</h3>
                        <p class='text-left leading-relaxed mb-8'>
                            The investment team at RWA is independent, which means our investment decisions and approach are not dictated by a corporate firm or a certain philosophy.  Our team is free to collaborate with many other investment teams throughout the United State to study and to discuss the markets, current investment theory and practical applications for our clients.  We regularly review primary investment approaches, such as indexing, factor-based investing and active management to construct portfolios that we believe will target the best risk-reward trade-offs for our clients.  </p>
                        <small className="flex-none">Cost</small>
                        <p className="mt-4">
                            Our advisory fee is a percentage of the money you invest with us (assets under management). The percentage is expressed as an annual amount, but paid each quarter and comes directly out of your investment account.
                        </p>

                        {/* <div className="flex">
                            <table className="w-1/2 text-xs border-collapse text-gray-500">
                                <thead className="text-left">
                                    <tr>
                                        <th className="p-2 border-b-2 border-gray-300">Assets Under Management</th>
                                        <th className="p-2 border-b-2 border-gray-300">Advisory Fee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="hover:bg-gray-100">
                                        <td className="p-1 border-b border-gray-300">{"<$1 Million"}</td>
                                        <td className="p-1 border-b border-gray-300">1.00%</td>
                                    </tr>
                                    <tr className="hover:bg-gray-100">
                                        <td className="p-1 border-b border-gray-300">$1-$2 Million</td>
                                        <td className="p-1 border-b border-gray-300">0.85%</td>
                                    </tr>
                                    <tr className="hover:bg-gray-100">
                                        <td className="p-1 border-b border-gray-300">$2-$5 Million</td>
                                        <td className="p-1 border-b border-gray-300">0.70%</td>
                                    </tr>
                                    <tr className="hover:bg-gray-100">
                                        <td className="p-1 border-b border-gray-300">$5-$10 Million</td>
                                        <td className="p-1 border-b border-gray-300">0.50%</td>
                                    </tr>
                                    <tr className="hover:bg-gray-100">
                                        <td className="p-1 border-b border-gray-300">$10+ Million</td>
                                        <td className="p-1 border-b border-gray-300">0.30%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> */}
                        <p className="mt-2">
                            {"<$1 Million: 1.00%"}
                        </p>
                        <p className="">
                            $1-$2 Million: 0.85%
                        </p>
                        <p className="">
                            $2-$5 Million: 0.70%
                        </p>
                        <p className="">
                            $5-$10 Million: 0.50%
                        </p>
                        <p className="">
                            $10+ Million: 0.30%
                        </p>


                    </div>

                    <div className="xl:basis-1/2 mt-8 xl:mt-0 rounded-2xl grid grid-cols-2 divide bg-gray-100 border border-gray-200">
                        {steps.map((step) => (
                            <div className={`p-6 ${step.type === 'primary' ? 'col-span- ' : ''}`}>
                                <div className='flex space-x-2 items-center'>
                                    <step.icon className='h-6 w-6 text-blue-500' />
                                    <h4 className='text-base font-medium'>{step.title}</h4>
                                </div>
                                <p className='text-sm ml-10'>{step.description}</p>
                            </div>
                        ))}
                    </div>

                </div>
            </section>

        </>
    )
}

export default InvestmentsSection;